import AppModule from './lib/AppModule';

import './style/Core.scss';

import 'bootstrap';
import $ from 'jquery';
import 'air-datepicker';
import 'air-datepicker/src/js/i18n/datepicker.nl';

export default class Core extends AppModule {
    constructor() {
        super();

        // Ref to this
        const self = this;

        // on DOMContentLoaded, publish DOMReady
        document.addEventListener('DOMContentLoaded', () => self.publish('DOMReady'));
    }

    DOMReady() {

        this.bindMenu();
        this.bindConfirms();

        $(document).on('change', 'input[name="data[Order][zakelijk]"]', function() {

            // Particulier
            if ($('#OrderZakelijk0').is(':checked')) {
                $('.zakelijk-container').prop('hidden', 1);
            } else {
                // Zakelijk
                $('.zakelijk-container').prop('hidden', 0);
            }
        });

        $('input[name="data[Order][zakelijk]"]:first').trigger('change');

        if (app.has_validation_errors) {

            $('html, body').animate({
                scrollTop: $('.error, .form-error').first().offset().top - 100
            }, 500);

        }

        $(document).on('change', '#OrderVerzendAdresGelijkAanFactuur', function() {
            if ($(this).is(':checked')) {
                $('.verzendadres-container').prop('hidden', 1);
            } else {
                $('.verzendadres-container').prop('hidden', 0);
            }
        });

        $('#OrderVerzendAdresGelijkAanFactuur').trigger('change');

        $(document).on('change', '#OrderAspergepakketten', function() {
            if ($(this).val() === 'alleen-sponsorbijdrage') {
                $('#OrderSponsorbijdrage').prop('checked', 1).trigger('change');
            }
        });

        $(document).on('change', '#OrderSponsorbijdrage', function() {
            if ($(this).is(':checked')) {
                $('.sponsorbijdrage-container').prop('hidden', 0);
            } else {
                $('.sponsorbijdrage-container').prop('hidden', 1);
            }
        });

        $('#OrderSponsorbijdrage').trigger('change');

        $('[data-toggle="tooltip"]').tooltip();

        $(document).on('click', '.send-reminder-form', function(e) {
            e.preventDefault();
            $('[data-reminder-form]').prop('hidden', 0);
        });

        $('.datepicker-afgerond-op').datepicker({
            language: 'nl',
            todayButton: new Date(),
            dateFormat: 'dd-mm-yyyy',
            altField: '#OrderAfgerondOp',
            altFieldDateFormat: 'yyyy-mm-dd'
        });

        $(document).on('change', '#OrderAction', function() {
            let selectedVal = $(this).val();
            let afgerondFieldsEl = $('[data-afgerond-fields]').hide();
            let tafelaarNaamEl = $('[data-tafelaar-naam-fields]').hide();
            switch (selectedVal) {
                case 'afgerond':
                    afgerondFieldsEl.show();
                    break;
                case 'tafelaar_naam':
                    tafelaarNaamEl.show().find('input').focus();
                    break;
            }

        });
        $(document).on('click', '#doOrder', function(e) {
            e.preventDefault();
            $(this).prop('disabled', 1).addClass('btn-info').val('Bezig met versturen...');
            $('#OrderHomeForm').submit();
            return false;
        });

        $(document).on('change', '[data-search-form]', function() {
            $(this).submit();
        });

        $(document).on('change', '#OrderSelectAll', function() {
            $('[name="data[Order][selected_orders][]"]').prop('checked', $(this).is(':checked'));
        });

        $(document).on('change', '[name="data[Order][selected_orders][]"]', function() {
            let allCheckboxes = $('[name="data[Order][selected_orders][]"]');
            $('#OrderSelectAll').prop('checked', allCheckboxes.length === allCheckboxes.filter(':checked').length);
        });
    }

    bindMenu() {
        $(document).on('click', 'a[href*="#"]:not([href="#"]):not([href="#0"])', function(e) {

            e.preventDefault();

            var hash = this.hash.replace('#', '');

            // Figure out element to scroll to
            var target = $(hash);
            target = target.length ? target : $('[name=' + hash.slice(1) + ']');

            // Does a scroll target exist?
            $('html, body').animate({
                scrollTop: target.offset().top - 110
            }, 500);

            $('.navbar-collapse').collapse('hide');
        });
    }

    bindConfirms() {
        $(document).on('click', '[data-confirm]', function(e) {
            if (confirm($(this).data('confirm'))) {
                return true;
            } else {
                e.preventDefault();
                return false;
            }
        });
    }
}
